import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import {
  useParams,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import BaseCard from "../../components/BaseCard";
import BasePage from "../../components/layouts/BasePage";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom";
import isArray from "lodash/isArray";
import {
  fetchMetrics,
  fetchUserPermissions,
  fetchAllFilters
} from "../../services/dataService";
import { launchReportInNewWindow } from "../../services/cognosService";
import { getUrlWithNoSpace } from "../../services/utils";
function MetricViewer() {
  const [, setSelectedDomain] = useState([]);
  const [domains] = useState([]);
  const [isMetricsLoading, setMetricsLoading] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);
  // const [isEditor, setIsEditor] = useState(false);
  const [backLocation, setBackLocation] = useState("");
  const [primaryDashboards, setPrimaryDashboards] = useState([]);
  const [dataDocumentation, setDataDocumentation] = useState();
  let history = useHistory();

  const btnRef = useRef();
  const { metricId } = useParams();
  const { data } = useLocation();

  const { register, errors } = useForm();
  const handleSelectDomain = async domain => {
    setSelectedDomain(domain);
  };

  useEffect(() => {
    setBackLocation(data);
    setMetricsLoading(true);
    fetchUserPermissions().then(resp => {
      // setIsEditor(
      //   resp.data
      //     .map(t => t.permissions.map(t => t.allowedAction))
      //     .flat(1)
      //     .includes("Read")
      // );
      Promise.all([fetchMetrics(), fetchAllFilters()])
        .then(res => {
          let lodedMetric = null;
          if (metricId && res[0].data.length > 0) {
            lodedMetric = res[0].data.find(oneMetric => {
              return oneMetric?.TDC_Object_Id?.toString() === metricId;
            });

            if (lodedMetric) {
              setDataDocumentation(
                res[1].data
                  ?.filter(e => e.type === "Data Domain")
                  ?.map(e => e["Data Domain"])
                  ?.flat()
                  ?.find(dc => dc["Data Domain"] === lodedMetric["Domain"])
              );

              setSelectedMetric(lodedMetric);
              const primaryDBs = JSON.parse(lodedMetric.Dashboards);
              try {
                if (Array.isArray(primaryDBs)) {
                  setPrimaryDashboards(primaryDBs);
                }
              } catch {}
            }
            setMetricsLoading(false);
          }
        })
        .catch(() => {
          setMetricsLoading(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasePage className=" p-4">
      <BaseCard>
        {isMetricsLoading && <Spinner>Fetching Metric Information</Spinner>}

        {!isMetricsLoading && (
          <>
            <Row>
              <Col sm={6}>
                {!!selectedMetric ? (
                  <Breadcrumb>
                    <LinkContainer to="/">
                      <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
                    </LinkContainer>
                    {backLocation !== "smart-search" ? (
                      <>
                        <LinkContainer to="/datalanding">
                          <Breadcrumb.Item as="div">
                            Data Catalog
                          </Breadcrumb.Item>
                        </LinkContainer>
                        <LinkContainer to="/datacatalog/metrics">
                          <Breadcrumb.Item as="div">
                            Enterprise Metrics
                          </Breadcrumb.Item>
                        </LinkContainer>
                      </>
                    ) : (
                      <LinkContainer to="/smartsearch/results">
                        <Breadcrumb.Item as="div">Search</Breadcrumb.Item>
                      </LinkContainer>
                    )}
                    <Breadcrumb.Item active>
                      {selectedMetric?.Name || "Not Available"}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                ) : (
                  <div className="p-3">
                    <Button
                      className="font-xsmall"
                      variant="link"
                      onClick={() => history.goBack()}
                    >
                      <FaArrowLeft /> Back
                    </Button>
                  </div>
                )}
              </Col>
            </Row>

            {!isMetricsLoading && !selectedMetric && (
              <div className="text-center mb-4">
                Metric information not found.
              </div>
            )}

            {!isMetricsLoading && !!selectedMetric && (
              <>
                <div className="MetricViewerHeader px-4 py-3">
                  <Row>
                    <Col sm={12}>
                      <div className="">Metrics</div>
                    </Col>
                  </Row>
                </div>
                <div className="MetricViewer bg-white px-3 py-3">
                  <Row>
                    <Col sm={12}>
                      <Button
                        className="font-xsmall"
                        variant="link"
                        onClick={() => history.goBack()}
                      >
                        <FaArrowLeft /> Back
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="MetricViewer bg-white px-2 py-1">
                  <Row>
                    <Col sm={12}>
                      <div className="metric-info px-3">
                        <h5 className="m-0 text-primary">
                          {selectedMetric?.Name}
                        </h5>
                      </div>
                      {/* <div className="metric-info px-3">
                Status: {selectedMetric?.Status}
              </div> */}
                      <div className="metric-info px-3">
                        <b>Domain:</b> {selectedMetric?.Domain}
                      </div>
                      <div className="metric-info px-3">
                        <b>Primary Dashboard: </b>

                        {isArray(primaryDashboards) &&
                        !!primaryDashboards.length
                          ? primaryDashboards.map((el, index) => (
                              <>
                                {!!index && <>,</>}
                                <a
                                  href="#"
                                  className="mx-2"
                                  target="_blank"
                                  onClick={() =>
                                    launchReportInNewWindow(
                                      getUrlWithNoSpace(el.name),
                                      el.dbid
                                    )
                                  }
                                >
                                  {el.name}
                                </a>
                              </>
                            ))
                          : "Not Available"}

                        {/* {Array.isArray(JSON.parse(selectedMetric?.Dashboards))} */}
                        {/* {selectedMetric?.Dashboards?.split(",")?.map(e =>
                          e.toLowerCase().includes("https") ? (
                            <a
                              href={e}
                              target="blank"
                              rel="noopener noreferrer"
                              style={{ cursor: "pointer" }}
                            >
                              {e}
                            </a>
                          ) : (
                            e
                          )
                        )} */}
                      </div>
                      <div className="px-3">
                        {!!dataDocumentation &&
                          isArray(dataDocumentation.helplink) && (
                            <div>
                              <b>Documentation: </b>
                              {dataDocumentation.helplink.map((e, i) => (
                                <>
                                  {!!i ? (
                                    <span style={{ color: "rgb(227 217 217)" }}>
                                      |
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <a
                                    href={e.url}
                                    className="mx-2"
                                    target="_blank"
                                  >
                                    {e.label}
                                  </a>
                                </>
                              ))}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <Form className="py-4 px-3">
                        <Form.Group as={Row} controlId="metric-domain">
                          <Form.Label column sm="3">
                            Domain
                          </Form.Label>
                          <Col sm="6">
                            <Select
                              isDisabled={true}
                              value={{
                                value: selectedMetric?.Domain,
                                label: selectedMetric?.Domain,
                                typeId: selectedMetric?.TypeID
                              }}
                              // defaultValue={domains[0]}
                              name="src"
                              options={domains.map(d => {
                                return {
                                  value: d.NAME,
                                  label: d.NAME,
                                  typeId: d.TypeID
                                };
                              })}
                              onChange={handleSelectDomain}
                              ref={register({ name: "Domain" })}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="metric-name">
                          <Form.Label column sm="3">
                            Metric Name
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              name="Name"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.Name}
                              maxLength={250}
                              value={selectedMetric?.Name}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="metric-description">
                          <Form.Label column sm="3">
                            Description
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              as="textarea"
                              name="Description"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.Description}
                              maxLength={250}
                              rows="4"
                              value={selectedMetric?.Description}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="metric-logic">
                          <Form.Label column sm="3">
                            Logic
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              as="textarea"
                              name="Logic"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.Logic}
                              maxLength={250}
                              value={selectedMetric?.Logic}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="metric-tags">
                          <Form.Label column sm="3">
                            Tags
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              as="textarea"
                              name="Tags"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.Tags}
                              maxLength={250}
                              value={selectedMetric?.Tags}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="metric-data-source">
                          <Form.Label column sm="3">
                            Database / Source
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              name="DataSource"
                              ref={register({
                                required: false,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.DataSource}
                              maxLength={250}
                              value={selectedMetric?.DataSource}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          controlId="metric-stored-procedure"
                        >
                          <Form.Label column sm="3">
                            Query
                          </Form.Label>
                          <Col sm="6">
                            {/* <Form.Control
                              disabled
                              as="textarea"
                              name="StoredProcedure"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.StoredProcedure}
                              maxLength={250}
                              value={selectedMetric?.StoredProcedure}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback> */}
                            {/* {selectedMetric?.StoredProcedure} */}
                            <div
                              style={{
                                height:
                                  selectedMetric?.StoredProcedure?.length >
                                    400 && "100px",
                                overflowY:
                                  selectedMetric?.StoredProcedure?.length >
                                    400 && "scroll"
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: selectedMetric?.StoredProcedure
                                }}
                              />
                            </div>
                          </Col>
                        </Form.Group>

                        {/* <Form.Group as={Row} controlId="metric-related-metrics">
                          <Form.Label column sm="3">
                            Comments
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              disabled
                              name="Metrics"
                              ref={register({
                                required: true,
                                validate: val => val.trim() !== ""
                              })}
                              type="text"
                              placeholder=""
                              isInvalid={!!errors.Metrics}
                              maxLength={250}
                              value={selectedMetric?.Comment}
                            />
                            <Form.Control.Feedback type="invalid">
                              Required
                            </Form.Control.Feedback>
                          </Col>
                          <Col sm="3">
                            <Button
                              type="submit"
                              variant="primary"
                              className="mt-1 mr-3"
                              style={{ display: "none" }}
                              ref={btnRef}
                            ></Button>
                          </Col>
                        </Form.Group> */}
                      </Form>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </>
        )}
      </BaseCard>
    </BasePage>
  );
}

export default MetricViewer;
