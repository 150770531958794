import React, { useState, useEffect } from "react";
import { useScreenSize } from "../../hooks/customHooks";
import classnames from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tabs, Tab } from "../../components/Tabs";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";

import "./AssetDescription.scss";
import { dataGovernanceScoreMap } from "../../services/assetColorMap";
import { APP_VARS } from "../../services/appVars";
import { Card, Modal } from "react-bootstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import DataRetentionInfo from "../../components/DataRetentionInfo/DataRetentionInfo";
import { fetchDCHelplinks } from "../../services/dataService";

function AssetDescription({
  asset,
  elements,
  sampleDataLoading,
  sampleData,
  showSampleData,
  documentations,
  isRelDocRoute,
  docsLoading
}) {
  const [activeTab, setActiveTab] = useState("elements");
  const [paddingAmount, setPaddingAmount] = useState();
  const [showDRDetails, setShowDRDetails] = useState(false);
  const [dataDocumentation, setDataDocumentation] = useState();

  const [isSmall, isMedium, isLarge, isExtraLarge, isXXL] = useScreenSize();
  useEffect(() => {
    fetchDCHelplinks().then(res => {
      setDataDocumentation(
        res.data.find(dc => dc["assetname"] === asset["Business Name"])
      );
    });
    if (isSmall || isMedium) {
      setPaddingAmount(30);
    }
    if (isLarge) {
      setPaddingAmount(60);
    }
    if (isExtraLarge) {
      setPaddingAmount(90);
    }
    if (isXXL) {
      setPaddingAmount(120);
    }
  }, [isSmall, isMedium, isLarge, isExtraLarge, isXXL]);

  useEffect(() => {
    if (isRelDocRoute) {
      setActiveTab("documentation");
      window.scrollTo(
        0,
        document.querySelector(".sx-asset-description__banner-section")
          ?.offsetHeight + 140
      );
    }
  }, []);

  const paddingStyle = {
    paddingLeft: paddingAmount,
    paddingRight: paddingAmount
  };

  const rowData = sampleData.samples?.slice(0, 99).map(el => {
    return sampleData.header.reduce((acc, cur, index) => {
      return { ...acc, [cur]: el[index] };
    }, {});
  });

  return (
    <div className="sx-asset-description">
      <div className="sx-asset-description__banner-section py-5">
        <Container fluid style={paddingStyle}>
          <Row className="d-flex align-items-center">
            <Col sm={12} lg={3}>
              <div className="font-weight-bold font-medium pb-3">
                {asset["Business Name"]}
              </div>
              <p className="font-small pb-3 ">
                {asset["Business Description"]}
              </p>
              {!!dataDocumentation && (
                <div
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    borderRadius: 10,
                    padding: 10
                  }}
                >
                  <b className="mb-2">Documentation: </b>
                  {dataDocumentation?.documents?.map((e, i) => (
                    <p className={!i ? "mt-2" : ""}>
                      <a
                        href={e.link}
                        className="mx-2"
                        target="_blank"
                        style={{
                          color: "whitesmoke",
                          textDecoration: "underline"
                        }}
                      >
                        {e.label}
                      </a>
                    </p>
                  ))}
                </div>
              )}
            </Col>
            <Col sm={12} lg={9}>
              <Row className="sx-asset-description__bottom-border-row pb-4">
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Owner"
                    value={asset["Owner"]}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Actual Asset Name"
                    value={asset["Name"]}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="System of Record"
                    value={asset["System Of Record"]}
                  />
                </Col>
                <Col sm={3}>
                  <DataGovernanceScore
                    variant="primary"
                    category="Data Governance Certification"
                    value={asset["Data Governance Certification"]}
                  />
                </Col>
              </Row>
              <Row className="sx-asset-description__bottom-border-row py-4">
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Platform"
                    value={asset["Asset Platform"]}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Data Domain"
                    value={asset["Data Domain"]}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Database"
                    value={asset["Database Name"]}
                  />
                </Col>
              </Row>
              <Row className="sx-asset-description__bottom-border-row pt-4">
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Refresh Frequency"
                    value={asset["Refresh Frequency"]}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="History or Snapshot"
                    value={asset["History"] === "N" ? "Snapshot" : "History"}
                  />
                </Col>
                <Col sm={3}>
                  <CategoryItem
                    variant="primary"
                    category="Data Retention Category"
                    value={asset["Data Retention Category"]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="sx-asset-description__tabs-section pt-3 pb-5">
        <Tabs
          style={paddingStyle}
          activeKey={activeTab}
          onSelect={k => setActiveTab(k)}
        >
          <Tab eventKey="elements" title="Elements">
            <Container fluid className="py-5" style={paddingStyle}>
              <Grid
                showExcel
                autoSizeColumns={false}
                width="100%"
                title={asset["Business Name"]}
                columnDefs={[
                  {
                    field: "Business Name",
                    sortable: true,
                    headerName: "Business Name",
                    resizable: true
                  },
                  {
                    field: "Business Description",
                    sortable: true,
                    headerName: "Business Description",
                    resizable: true
                  },
                  {
                    field: "Name",
                    sortable: true,
                    sort: "asc",
                    headerName: "Name",
                    resizable: true
                  },
                  {
                    field: "Data Type",
                    sortable: true,
                    headerName: "Data Type",
                    resizable: true
                  },
                  {
                    field: "Last Modified Date",
                    sortable: true,
                    headerName: "Last Modified Date",
                    resizable: true
                  },
                  {
                    field: "Length",
                    sortable: true,
                    headerName: "Length",
                    resizable: true
                  },
                  {
                    field: "Scale",
                    sortable: true,
                    headerName: "Scale",
                    resizable: true
                  },
                  {
                    field: "Native Type",
                    sortable: true,
                    headerName: "Native Type",
                    resizable: true
                  },
                  {
                    field: "Compliance Category",
                    sortable: true,
                    headerName: "Compliance Category",
                    resizable: true
                  },
                  {
                    field: "Direct Identifier",
                    sortable: true,
                    headerName: "Direct Identifier (PII)",
                    resizable: true
                  },
                  {
                    field: "DataProfile.DistinctRows",
                    sortable: true,
                    headerName: "Distinct Rows",
                    resizable: true
                  },
                  {
                    field: "DataProfile.EmptyRows",
                    sortable: true,
                    headerName: "Empty Rows",
                    resizable: true
                  },
                  {
                    field: "DataProfile.InferredDataTypes",
                    sortable: true,
                    headerName: "Inferred DataTypes",
                    resizable: true
                  },
                  {
                    field: "DataProfile.MaxLength",
                    sortable: true,
                    headerName: "Max Length",
                    resizable: true
                  },
                  {
                    field: "DataProfile.MaxValue",
                    sortable: true,
                    headerName: "Max Value",
                    resizable: true
                  },
                  {
                    field: "DataProfile.Mean",
                    sortable: true,
                    headerName: "Mean",
                    resizable: true
                  },
                  {
                    field: "DataProfile.Median",
                    sortable: true,
                    headerName: "Median",
                    resizable: true
                  },
                  {
                    field: "DataProfile.MinLength",
                    sortable: true,
                    headerName: "Min Length",
                    resizable: true
                  },
                  {
                    field: "DataProfile.MinValue",
                    sortable: true,
                    headerName: "Min Value",
                    resizable: true
                  }
                ]}
                rowData={elements}
              />
            </Container>
          </Tab>
          {showSampleData && (
            <Tab eventKey="sample" title="Sample Data">
              <Container fluid className="py-5" style={paddingStyle}>
                {sampleDataLoading && <Spinner>Fetching Sample Data</Spinner>}
                {!sampleDataLoading && !rowData?.length && (
                  <div className="text-center">No sample data available.</div>
                )}
                {!sampleDataLoading && !!rowData?.length && (
                  <Grid
                    showExcel
                    width="100%"
                    title={asset["Business Name"]}
                    autoSizeColumns={false}
                    columnDefs={
                      sampleData.header
                        ? sampleData.header.map((i, index) => ({
                            field: i,
                            headerName: i,
                            sortable: true,
                            resizable: true,
                            sort: index === 0 ? "asc" : undefined
                          }))
                        : []
                    }
                    rowData={rowData}
                  />
                )}
              </Container>
            </Tab>
          )}
          <Tab eventKey="drdetails" title="Data Retention Details">
            <Container fluid className="pt-4" style={paddingStyle}>
              <Row>
                <Col sm={12}>
                  <span
                    className="btn-link pointer"
                    onClick={() => setShowDRDetails(true)}
                  >
                    <AiFillInfoCircle style={{ verticalAlign: "text-top" }} />
                    {"  "}
                    What does this mean?
                  </span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Card className="sx-asset-description__drcard">
                    <Card.Header
                      as="h5"
                      className="p-3 font-weight-bold sx-asset-description__dr-card-header"
                    >
                      Data Retention Details (Period shown in months)
                    </Card.Header>
                    <Card.Body>
                      <DRCategoryItem
                        category="Data Retention Category"
                        value={asset["Data Retention Category"]}
                      />
                      <DRCategoryItem
                        category="Hot Retention Period"
                        value={asset["Hot Retention Period - Months"]}
                      />
                      <DRCategoryItem
                        category="Cold Retention Period"
                        value={asset["Cold Retention Period - Months"]}
                      />
                      <DRCategoryItem
                        category="Deep Cold Archive Enabled"
                        value={asset["Is Deep Cold Archive Enabled"]}
                      />
                      <DRCategoryItem
                        category="Retention Policy Field"
                        value={asset["Retention Date Field"]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="documentation" title="Documentation">
            <Container fluid className="py-5" style={paddingStyle}>
              {!docsLoading ? (
                Array.isArray(documentations) && !!documentations.length ? (
                  <div className="px-2 grid-class">
                    <Grid
                      width="100%"
                      autoSizeColumns={true}
                      isExecutiveGrid
                      columnDefs={[
                        {
                          field: "documentName",
                          sortable: false,
                          headerName: "Document Name",
                          resizable: true,
                          minWidth: "400",
                          cellRendererFramework: ({ data, value }) => {
                            return (
                              <a
                                style={{
                                  cursor: "pointer",
                                  fontWeight: "500"
                                }}
                                className="search-primary"
                                href={data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {value}
                              </a>
                            );
                          }
                        },
                        {
                          field: "description",
                          sortable: false,
                          minWidth: "500",
                          headerName: "Description",
                          resizable: true
                        }
                      ]}
                      rowData={documentations}
                    />
                  </div>
                ) : (
                  <div className="text-center mt-3">Coming Soon</div>
                )
              ) : (
                <Spinner> Loading...</Spinner>
              )}
            </Container>
          </Tab>
        </Tabs>
      </div>
      <Modal
        className="dgc-legend-modal"
        show={showDRDetails}
        onHide={() => setShowDRDetails(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Data Retention Definitions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="py-2" style={paddingStyle}>
            <DataRetentionInfo />
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function CategoryItem({ category, value, variant = "primary" }) {
  return (
    <div className={classnames("sx-category-item pb-3", variant)}>
      <div className="sx-category-item__category pb-3">{category}</div>
      <div className="sx-category-item__value">{value}</div>
    </div>
  );
}

function DRCategoryItem({ category, value }) {
  return (
    <Row className={classnames("sx-category-item py-3")}>
      <Col sm={5} className="sx-category-item__drcategory">
        {category}:
      </Col>
      <Col sm={7} className="sx-category-item__drvalue">
        {value}
      </Col>
    </Row>
  );
}

function DataGovernanceScore({ category, value }) {
  return (
    <div className="sx-category-item pb-3 primary">
      <div className="sx-category-item__category pb-3">{category}</div>
      <div className="sx-category-item__value">
        <Row className="align-items-center justify-content-center p-0">
          {Object.keys(dataGovernanceScoreMap).map(score => (
            <Col
              key={score}
              className={classnames(
                {
                  inActiveClass: value !== score
                },
                "scoreCol px-1 text-center"
              )}
            >
              <img
                src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/${score}.svg`}
                alt={score}
              />
              <div className="text-white">{score}</div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default AssetDescription;
